import { createRouter, createWebHistory } from "vue-router";
import store from "../store/store";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/page/${view}.vue`);
}

const publicPath = process.env.BASE_URL;
const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageHome"),
        },
        meta: { name: "welcome" },
      },
    ],
  },
  // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
  {
    path: "/:pathMatch(.*)",
    name: "bad-not-found",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageHome"),
        },
        meta: { name: "welcome" },
      },
    ],
  },
  //HOME
  {
    path: publicPath,
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageHome"),
        },
        meta: { name: "welcome" },
      },
    ],
  },
  //LEISTUNGEN
  {
    path: publicPath + "leistungen",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageLeistungen"),
        },
        meta: { name: "leistungen" },
      },
    ],
  },
  //PROJEKTE
  {
    path: publicPath + "projekte",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageProjekte"),
        },
        meta: { name: "projekte" },
      },
    ],
  },
  // DATENSCHUTZ
  {
    path: publicPath + "datenschutz",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageDatenschutz"),
        },
        meta: { name: "datenschutz" },
      },
    ],
  },
  // IMPRESSUM
  {
    path: publicPath + "impressum",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageImpressum"),
        },
        meta: { name: "impressum" },
      },
    ],
  },

  //HTML5 Abstimmungen!
  
  // Laut von Leise
  {
    path: publicPath + "lvl/glamour",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/lvl/glamour/",
      campName: "glamour",
    },
  },
  {
    path: publicPath + "lvl/ibis",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/lvl/ibis/",
      campName: "ibis",
      campButtons:true, 
    },
  },
    // SEVENONE
  {
    path: publicPath + "sevenone/quiz",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/quiz/",
      campName: "quiz",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"bis 21.3",
      camp_2:"heute 22.3",
      camp_3:"ab 23.3",
    },
  },
  {
    path: publicPath + "sevenone/bb_2025",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/bb_2025/",
      campName: "bb_2025",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"bis 23.2",
      camp_2:"heute 24.2",
      camp_3:"ab 25.2",
    },
  },
  {
    path: publicPath + "sevenone/bb_2025_sonder",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/bb_2025_sonder/",
      campName: "bb_2025",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"bis 23.2",
      camp_2:"heute 24.2",
      camp_3:"ab 25.2",
    },
  },
  {
    path: publicPath + "sevenone/jk_q4",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/jk_q4/",
      campName: "JK",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"bis 4.11",
      camp_2:"5.11",
      camp_3:"ab 6.11 ",
    },
  },
  {
    path: publicPath + "sevenone/destinationx",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/destinationx/",
      campName: "destinationx",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"bis 6.11",
      camp_2:"7.11",
      camp_3:"ab 8.11 ",
    },
  },
  {
    path: publicPath + "sevenone/faceforward",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/faceforward/",
      campName: "faceforward",
      campButtons: false, 
      campButtonsAlternative:true,
      camp_1:"bis 6.11",
      camp_2:"7.11",
      camp_3:"ab 8.11 ",
    },
  },
  {
    path: publicPath + "sevenone/joynme",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/joynme/",
      campName: "JoynMe",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/paarlove",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/paarlove/",
      campName: "Paarlove",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/zids",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/zids/",
      campName: "Zurück in der Schule",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/jenkecrime",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/jenkecrime/",
      campName: "Jenke Crime",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/jenkecrime_2023",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/jenkecrime_2023/",
      campName: "jenkecrime_2023",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/jenke_ki",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/jenke_ki/",
      campName: "jenke_ki",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/jenke_crime",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/jenke_crime/",
      campName: "jenke_crime",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "jenkecrime_teslinks",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/jenkecrime_teslinks/",
      campName: "jenkecrime_2023",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/six",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/six/",
      campName: "Six",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/nhl",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/nhl/",
      campName: "NHL",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/dieunschlagbaren",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/dieunschlagbaren/",
      campName: "NHL",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/dieunschlagbaren_f2",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/dieunschlagbaren_f2/",
      campName: "NHL",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/rugbywm",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/rugbywm/",
      campName: "rugbywm",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/tvog",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/tvog/",
      campName: "tvog",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"Vorankündigung",
      camp_2:"21.SEP",
      camp_3:"22.SEP",
    },
  },
  {
    path: publicPath + "sevenone/tvog_f2",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/tvog_f2/",
      campName: "tvog_f2",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"23.9",
      camp_2:"24.9",
      camp_3:"24.9",
    },
  },
  {
    path: publicPath + "sevenone/tvog_f3",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/tvog_f3/",
      campName: "tvog_f2",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"5-7.12",
      camp_2:"8.12",
      camp_3:"8.12",
    },
  },
  {
    path: publicPath + "sevenone/nba_f1",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/nba_f1/",
      campName: "nba_f1",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"24.10 - 27.10",
      camp_2:"28.10",
      camp_3:"29.10",
    },
  },
  {
    path: publicPath + "sevenone/nba_f2u3",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/nba_f2u3/",
      campName: "nba_f2u3",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"24.10 - 27.10",
      camp_2:"4.11. und 11.11",
      camp_3:"5.11. und 12.11",
    },
  },
  {
    path: publicPath + "sevenone/WSMDS",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/WSMDS/",
      campName: "WSMDS",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"bis 7.9",
      camp_2:"8.9",
      camp_3:"ab 9.9 ",
    },
  },
  {
    path: publicPath + "sevenone/WSMDS_SONDER",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/WSMDS_SONDER/",
      campName: "WSMDS_SONDER",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"bis 7.9",
      camp_2:"8.9",
      camp_3:"ab 9.9 ",
    },
  },
  {
    path: publicPath + "sevenone/derupir",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/derupir/",
      campName: "derupir",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"bis 7.9",
      camp_2:"8.9",
      camp_3:"ab 9.9 ",
    },
  },
  {
    path: publicPath + "sevenone/diesuperdupershow",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/diesuperdupershow/",
      campName: "diesuperdupershow",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"bis 16.9",
      camp_2:"17.9",
      camp_3:"ab 18.9 ",
    },
  },
  {
    path: publicPath + "sevenone/Spreewaldklinik",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/Spreewaldklinik/",
      campName: "Spreewaldklinik",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"23.8 -28.08",
      camp_2:"29.8",
      camp_3:"Ab 30.8 ",
    },
  },
  

  {
    path: publicPath + "sevenone/jff",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/jff/",
      campName: "jff",
    },
  },
  {
    path: publicPath + "sevenone/DRADN",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/DRADN/",
      campName: "DRADN",
    },
  },
  {
    path: publicPath + "sevenone/rampensau",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/rampensau/",
      campName: "rampensau",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/reimanns",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/reimanns/",
      campName: "reimanns",
      campButtons:true, 
    },
  },
  {
    path: publicPath + "sevenone/bigbrother",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/bigbrother/",
      campName: "bigbrother",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"bis einschließlich 3.3.",
      camp_2:"am 4.3",
      camp_3: "ab 5.3",
    },
  },
  {
    path: publicPath + "sevenone/bigbrother_sonder",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/bigbrother_sonder/",
      campName: "bigbrother_sonder",
      campButtons:true, 
    },
  },

  {
    path: publicPath + "sevenone/pup",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/pup/",
      campName: "pup",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"bis 16.2",
      camp_2:"17.2",
      camp_3:"ab 18.2",
    },
  },
  // 
  {
    path: publicPath + "DTDW/",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/DTDW/",
      campName: "DTDW",
    },
  },

  // HORNBACH

  {
    path: publicPath + "hornbach/ICR_2025",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/ICR_2025/",
      campName: "ICR_2025",
    },
  },

  {
    path: publicPath + "hornbach/HADA",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/HADA/",
      campName: "HADA",
    },
  },

  {
    path: publicPath + "hornbach/JFENA",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/JFENA/",
      campName: "JFENA",
    },
  },

  {
    path: publicPath + "hornbach/buchstaben",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/buchstaben/",
      campName: "Buchstaben",
    },
  },
  {
    path: publicPath + "hornbach/buchstabenklau",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/buchstabenklau/",
      campName: "Buchstabenklau",
    },
  },

  {
    path: publicPath + "hornbach/ldnmm",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/ldnmm/",
      campName: "ldnmm",
    },
  },
  {
    path: publicPath + "hornbach/ldnmm_f2",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/ldnmm_f2/",
      campName: "ldnmm_f2",
    },
  },
  {
    path: publicPath + "hornbach/ldnmm_f3",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/ldnmm_f3/",
      campName: "ldnmm_f3",
    },
  },
  
  {
    path: publicPath + "hornbach/qm",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/qm/",
      campName: "qm",
    },
  },
  {
    path: publicPath + "sevenone/WKDBFDG",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/WKDBFDG/",
      campName: "WKDBFDG",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"26.6. – 2.7.",
      camp_2:"3.7",
      camp_3:"10.07. - 17.07.",
    },
  },
  {
    path: publicPath + "sevenone/WKDBFDG/KB",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/sevenone/WKDBFDG/KB/",
      campName: "WKDBFDG",
      campButtons: true, 
      campButtonsAlternative:true,
      camp_1:"26.6. – 2.7.",
      camp_2:"3.7",
      camp_3:"10.07. - 17.07.",
    },
  },
  // FAHNDUNG,
  {
    path: publicPath + "hornbach/fahndung",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/fahndung/",
      campName: "Fahndung",
    },
  },

  {
    path: publicPath + "hornbach/sogeht_2024_f1",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/sogeht_2024_f1/",
      campName: "So Geht Flight 1",
    },
  },
  {
    path: publicPath + "hornbach/sogeht_2024_f2",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/sogeht_2024_f2/",
      campName: "So Geht Flight 2",
    },
  },
  {
    path: publicPath + "hornbach/sogeht_2024_f3",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/sogeht_2024_f3/",
      campName: "So Geht Flight 3",
    },
  },
  {
    path: publicPath + "hornbach/sogeht_2024_f4",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/sogeht_2024_f4/",
      campName: "So Geht Flight 4",
    },
  },
  {
    path: publicPath + "hornbach/sogeht_2024_f5",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/sogeht_2024_f5/",
      campName: "So Geht Flight 5",
    },
  },
  {
    path: publicPath + "hornbach/sogeht_2024_f6",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/sogeht_2024_f6/",
      campName: "So Geht Flight 6",
    },
  },
  {
    path: publicPath + "hornbach/sogeht_2024_f7",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/sogeht_2024_f7/",
      campName: "So Geht Flight 7",
    },
  },
  {
    path: publicPath + "hornbach/sogeht_2024_f8",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/sogeht_2024_f8/",
      campName: "So Geht Flight 8",
    },
  },
  {
    path: publicPath + "hornbach/sogeht_2024_f9",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/sogeht_2024_f9/",
      campName: "So Geht Flight 9",
    },
  },
  {
    path: publicPath + "hornbach/sogeht_2024_f10",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/sogeht_2024_f10/",
      campName: "So Geht Flight 10",
    },
  },
  {
    path: publicPath + "hornbach/zuvielbezahler",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/hornbach/zuvielbezahler/",
      campName: "ZuVielBezahler",
    },
  },


  // 
  {
    path: publicPath + "nfq/o2",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/nfq/o2/",
      campName: "o2",
    },
  },
  {
    path: publicPath + "ZDF/sdemo",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/ZDF/sdemo/",
      campName: "ZDF",
    },
  },
  {
    path: publicPath + "ZDF/sdoku",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/ZDF/sdoku/",
      campName: "ZDF",
    },
  },


  {
    path: publicPath + "db/ads",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/db/ads/",
      campName: "",
    },
  },

  {
    path: publicPath + "spc2/omuc",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/spc2/omuc/",
      campName: "",
    },
  },


  // FITNESSFIRST

  {
    path: publicPath + "fitnessfirst/januar_2025/",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/fitnessfirst/januar_2025/",
      campName: "Flights",
    },
  },
  {
    path: publicPath + "fitnessfirst/februar_2025/",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/fitnessfirst/februar_2025/",
      campName: "Flights",
    },
  },


  // ALLIANZ

  {
    path: publicPath + "allianz/kfz",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/allianz/kfz/",
      campName: "kfz",
    },
  },
  {
    path: publicPath + "allianz/HKV-Banner",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/allianz/HKV-Banner/",
      campName: "HKV-Banner",
    },
  },

  // NOGA

  {
    path: publicPath + "noga/umweltbank",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/noga/umweltbank/",
      campName: "umweltbank",
    },
  },
  {
    path: publicPath + "noga/ub_f1",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/noga/ub_f1/",
      campName: "ub_f1",
    },
  },

  // WWG
  {
    path: publicPath + "wwg/hig/januar",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/wwg/hig/januar/",
      campName: "HIG januar",
    },
  },
  {
    path: publicPath + "wwg/dd/issgut",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/wwg/dd/issgut/",
      campName: "DD",
    },
  },
  {
    path: publicPath + "wwg/dd/fruehjahr_2025",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/wwg/dd/fruehjahr_2025/",
      campName: "DD",
    },
  },

  // SHOWCASES
  {
    path: publicPath + "showcases/html5ads/",
    component: loadView("PageAds"),
    meta: {
      campPath: "https://html5ad.com/ads/showcases/html5ads/",
      campName: "HTML5Ads",
    },
  },
 

];

// NEEDS

const router = createRouter({
  base: process.env.publicPath,
  history: createWebHistory(),
  routes, // short for `routes: routes`

  scrollBehavior(to, from, savedPosition) {
    // savedPosition

    // always scroll to top
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to !== from) {
        return { top: 0 };
      }
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta) {
    store.commit("setCampOptions", to.meta);
    if (to.meta.campButtonsAlternative) {
      store.commit("setCampButtonsAlternative", to.meta);
    }
    next();
  } else {
    next();
  }
});
// router.beforeEach(() => {
//   window.scrollTo(0, 0);
// });

router.afterEach((to) => {
  window.scrollTo(0, 0);
  if (to.meta.name) {
    store.commit("setActiveRouteName", to.meta.name);
  } else {
    store.commit("setActiveRouteName", "welcome");
  }
  store.commit("setActiveRoute", to.href);
});

router.resolve({
  name: "bad-not-found",
  params: { pathMatch: "not/found" },
}).href; // '/not%2Ffound'
router.resolve({
  name: "not-found",
  params: { pathMatch: ["not", "found"] },
}).href; // '/not/found'

export default router;
